/* 
 * @Author: 曹俊杰 
 * @Date: 2022-09-28 17:00:04
 * @Module: 外部客户详情
 */
<template>
  <div class="CustomerManagementDetail">
    <div ref="wrap">
      <el-row :gutter="24">
        <el-col :span="24">
          <div class="left1">
            <div class="title">
              <div class="primary">{{data.name}}</div>
              <el-button type="primary" @click="revision" size="small" >编辑修改</el-button>
            </div>
            <div class="content">
              <el-row>
                <el-col :span="18">
                  <div class="content-l">
                    <el-descriptions class="margin-top" :column="3" direction="horizontal">

                      <el-descriptions-item label="对接法务">{{data.legalName||'--'}}</el-descriptions-item>

                      <el-descriptions-item label="对接群名">{{data.groupName||'--'}}</el-descriptions-item>

                      <el-descriptions-item label="关联公司"> <template>
                          <div v-if="data.relatedCompanys.length">
                            <p v-for="(item,index) in data.relatedCompanys" :key="index">
                              {{item.name}}
                            </p>
                          </div>
                          <div v-else>--</div>

                        </template></el-descriptions-item>
                      <el-descriptions-item label="公司流水">{{data.scale||'--'}}</el-descriptions-item>
                      <el-descriptions-item label="服务合同">
                        <pdfPreview :pdfUrls="data.contractUrls"></pdfPreview>
                      </el-descriptions-item>
                      <el-descriptions-item label="主营业务">
                        <template>
                          <div v-if="data.businesses.length">
                            <span v-for="(item,index) in data.businesses" :key="item.businessId">{{item.name}}<span v-if="(index+1) < data.businesses.length">、</span></span>
                          </div>
                          <span v-else>--</span>

                        </template>
                      </el-descriptions-item>
                      <el-descriptions-item label="主做平台">
                        <div v-if="data.platforms.length">
                          <span v-for="(item,index) in data.platforms" :key="item.platformId">{{item.name}}<span v-if="(index+1) < data.platforms.length">、</span></span>
                        </div>
                        <span v-else>--</span>

                      </el-descriptions-item>
                      <el-descriptions-item label="营业执照">
                        <imagePreview v-if="data.licenseUrl" :imageUrls="data.licenseUrl" />
                        <span v-else>--</span>
                      </el-descriptions-item>
                      <el-descriptions-item label="录入时间">
                        <span v-if="data.createdAt">{{data.createdAt|timeFormat}}</span>
                        <span v-else>--</span>
                      </el-descriptions-item>
                      <el-descriptions-item label="案件数量">{{data.caseCount||'--'}}</el-descriptions-item>
                    </el-descriptions>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="content-r">
                    <div class="content-r-l">
                      <div class="top">当前状态</div>
                      <div class="bottom" v-if="data.state=='1'" style="color:#0AC258;">正常</div>
                      <div class="bottom" v-else-if="data.state=='0'" style="color:#FF0D0D;">超期</div>
                      <div class="bottom" v-else>--</div>
                    </div>
                    <div class="content-r-r">
                      <div class="top">累计缴费</div>
                      <div class="bottom">{{data.amount}}元</div>
                    </div>
                  </div>
                </el-col>
              </el-row>

            </div>
          </div>
          <div class="left2">
            <div class="title" style="font-size:14px;">客户联系人情况</div>
            <div class="content">
              <el-descriptions :column="3">
                <el-descriptions-item label="联系方式">
                  {{data.contacts||'--'}}
                </el-descriptions-item>

                <el-descriptions-item label="客户情况" :span="2">
                  {{data.situation||'--'}}
                </el-descriptions-item>
              </el-descriptions>
            </div>
          </div>
          <div class="cost">
            <div class="title">
              <span class="left">公司缴费 </span>
              <el-button type="primary" size="small" @click="addOuterPaymentForm">添加公司缴费</el-button>
            </div>

            <div class="content">
              <el-table :data="outerPaymentList" size="mini" style="width: 100%;">

                <el-table-column label="金额" prop="amount" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span class="primary">¥{{ scope.row.amount }}</span>
                  </template>
                </el-table-column>

                <el-table-column label="开始日期" prop="deadline" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span>
                      <span v-if="scope.row.startAt">{{ scope.row.startAt|timeFormat }}</span>
                      <span v-else>--</span>
                    </span>
                  </template>
                </el-table-column>

                <el-table-column label="截止日期" prop="deadline" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span>
                      <span v-if="scope.row.endAt">{{ scope.row.endAt|timeFormat }}</span>
                      <span v-else>--</span>
                    </span>
                  </template>
                </el-table-column>

                <el-table-column label="操作" width="120" :resizable="false" >
                  <template slot-scope="scope">
                    <el-button type="text" size="mini" @click="()=>revisionOuterPaymentForm(scope.row)">编辑</el-button>
                    <el-button type="text" size="mini" @click="()=>deleteOuterPayment(scope.row)">删除</el-button>
                  </template>
                </el-table-column>

              </el-table>
            </div>

          </div>
        </el-col>
      </el-row>
    </div>

    <outerPaymentForm @success="outerPaymentRefresh" ref="outerPaymentForm" />
    <uploadDialog ref="addLicenseUrl" @success="addLicenseUrlSuccess" />
    <OuterCustomerManagementForm ref='add' @success="getCompany" />
  </div>
</template>
 <script>
import { mapState } from "vuex";
import uploadDialog from "@/components/uploadDialog"
import outerPaymentForm from "@/views/OuterCustomerManagement/components/outerPaymentForm"
import imagePreview from "@/components/imagePreview"
import pdfPreview from "@/components/pdfPreview"
import OuterCustomerManagementForm from "@/views/OuterCustomerManagement/components/OuterCustomerManagementForm"
export default {
  components: {
    outerPaymentForm,
    uploadDialog,
    imagePreview,
    OuterCustomerManagementForm,
    pdfPreview
  },
  data () {
    return {
      data: {
        areaCode: "",
        areaName: "",
        balance: 0,
        businesses: [],
        caseCount: 0,
        contacts: [],
        createdAt: 0,
        expiredAt: null,
        groupName: null,
        lastTrendAt: null,
        legalName: "",
        name: "",
        partners: [],
        platforms: [],
        relatedCompanys: [],
        scale: "",
        scaleId: 5,
        signState: null,
        signedAt: 0,
        signerName: "",
        situation: null,
        updatedAt: 0,
        uuid: "",
        licenseUrl: ''
      },
      outerPaymentList: []
    };
  },
  computed: {
    ...mapState({
      user: state => state.userInfo.user
    })
  },
  mounted () {

  },

  methods: {
    // 刷新
    outerPaymentRefresh () {
      this.getCompany();
      this.getOuterPaymentList()
    },
    // 公司详情
    async getCompany () {
      const { status, data, info } = await this.$api.getOuterCompanyDetail(this.$route.params);
      if (status == 200) {
        this.data = data;
      } else {
        this.$message({
          message: info,
          type: 'error'
        })
      }
    },
    // 公司缴费列表
    async getOuterPaymentList () {
      const { status, data } = await this.$api.getOuterPaymentList({ outerCompanyUuid: this.$route.params.uuid });
      if (status === 200) {
        this.outerPaymentList = data;
      }
    },
    //修改公司缴费
    revisionOuterPaymentForm (data) {
      this.$refs.outerPaymentForm.show({ type: 'revision', data });
    },
    // 添加公司缴费
    addOuterPaymentForm () {
      this.$refs.outerPaymentForm.show({ type: 'add' })
    },
    //删除公司缴费
    async deleteOuterPayment ({ uuid }) {
      try {
        await this.$confirm(`此操作将永久删除该条数据, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        const { status, info } = await this.$api.deleteOuterPayment({ uuid })
        if (status === 200) {
          this.$message({
            type: 'success',
            message: info
          })
          this.outerPaymentRefresh()
        } else {
          this.$message({
            type: 'error',
            message: info
          })
        }
      } catch (error) {
        console.log(error)
      }

    },
    // 上传营业执照
    addLicenseUrl () {
      this.$refs.addLicenseUrl.show();
    },
    // 上传营业执照成功
    addLicenseUrlSuccess (res) {
      console.log(res)
    },
    // 编辑公司
    revision () {
      const platformIds = this.data.platforms.map(item => item.platformId)
      const businessIds = this.data.businesses.map(item => item.businessId)
      this.$refs.add.show({ data: { ...this.data, platformIds, businessIds }, type: 'revision' })
    }
  },
  watch: {
    "$route.params": {
      handler () {
        this.$nextTick(() => {
          this.getCompany();
          this.getOuterPaymentList();
        })
      },
      immediate: true
    }
  }
};
 </script>
 <style lang='scss' scoped>
.CustomerManagementDetail {
  /deep/.DynamicStateList {
    .content {
      height: 460px;
    }
  }
  .left1 {
    background: #fff;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 56px;
      padding: 0 16px;
      font-weight: 400;
      font-size: 18px;
      .btn {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #0d72ff;
      }
    }
    .content {
      padding: 10px 0;
      &-l {
        padding: 0 16px;
      }
      &-r {
        padding: 0 16px;
        display: flex;
        justify-content: flex-end;
        .top {
          font-size: 14px;
          line-height: 21px;
          color: #666666;
          text-align: right;
        }
        .bottom {
          font-weight: 400;
          font-size: 20px;
          line-height: 30px;
          color: #0d72ff;
          text-align: right;
        }
        &-l {
          margin-right: 40px;
        }
      }
    }
  }
  .left2 {
    margin-top: 24px;
    background: #fff;
    .title {
      line-height: 56px;
      padding: 0 16px;
      font-size: 16px;
    }
    .content {
      padding: 10px 16px;
    }
  }
  .cost {
    margin-top: 24px;
    background: #fff;
    .content {
      padding: 0 16px 20px 16px;
    }
    .title {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #666666;
      height: 56px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .btn {
        font-size: 14px;
      }
    }
  }
}
</style>